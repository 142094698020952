import React from "react";

function Test() {
  return (
    <div className="w-[5000px] h-[5000px] bg-gray-200 relative">
      <table className="w-full h-full">
        <thead className="sticky top-0 left-0 z-10">
          <tr className="bg-orange-500">
            <th className="border p-1 sticky top-0 left-0 z-20 bg-rose-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-20 bg-rose-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-20 bg-rose-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
        </thead>
        <tbody>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
          <tr className="relative">
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <th className="border p-1 sticky top-0 left-0 z-10 bg-blue-500">
              ㅎㅎㅎ
            </th>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
            <td className="border p-1">ㅇㅇㅇㅇㅇ</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Test;
